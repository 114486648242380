<template>
  <div>
    <b-card class="card mb-1" title="Data Request Pelatihan">
      <b-row v-if="!data.id">
        <b-col class="d-flex justify-content-center mt-4">
          <div class="text-center mt-4">
            <b-spinner
              variant="primary"
              class="mr-1"
            />
            <p class="mt-1">
              Memuat Data Request Pelatihan
            </p>
          </div>
        </b-col>
      </b-row>
      <div class="mt-2" v-if="data.id">
        <b-row>
          <b-col sm="12" class="mb-1"
            ><b-form-group>
              <label class="d-block">Nama Pelatihan:</label>
              <h4>{{ data.name }}</h4>
            </b-form-group>
          </b-col>

          <b-col sm="12" class="mb-1">
            <b-form-group>
              <label class="d-block">Tujuan Pelatihan:</label>
              <p>{{ data.purpose }}</p>
            </b-form-group>
          </b-col>

          <b-col sm="12" class="mb-1">
            <b-form-group>
              <label class="d-block">User:</label>
                  <b-avatar
                    :src="data.user.avatar"
                    :text="avatarText(data.user.name)"
                    variant="light-primary"
                    class="mr-1"
                  />
                  <span class="text-nowrap">{{ data.user.name }}</span>
            </b-form-group>
          </b-col>

          <b-col sm="12" class="mb-1">
            <b-form-group>
              <label class="d-block">Status:</label>
              <b-badge
                :variant="data.status | statusClass"
                class="small-gap-right"
              >
                {{ data.status }}
              </b-badge>
            </b-form-group>
          </b-col>

          <b-col sm="12" class="mb-1">
            <b-form-group>
              <label class="d-block">Deskripsi Pelatihan:</label>
              <p v-html="data.description"></p>
            </b-form-group>
          </b-col>
        </b-row>
        <div>
          <b-button
            v-if="(user.id === data.created_by_id) && data.status != 'Diterima'"
            variant="primary"
            class="float-right"
            :to="{
              name: checkAuthorizeRole('training-requests.edit'),
              params: {
                trainingRequestId: data.id,
              },
            }"
          >
            <div class="d-flex align-items-center" >
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
                @click.prevent="submitForm"
              />
              Edit
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>
  
  <script>
// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormGroup,
  BSpinner,
  BBadge
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { reqGetTrainingRequest } from "@/api/user/trainingRequest";
import { checkAuthorizeRole } from "@/auth/utils";
import { pascalCase, avatarText } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormGroup,
    BSpinner,
    BBadge
  },
  data() {
    return {
      isLoading: false,
      data: {},
      user: JSON.parse(localStorage.getItem('userData')),
    };
  },
  mounted() {
    this.getData();
  },
  filters: {
    statusClass(status) {
      if (status == "Menunggu Persetujuan") {
        return "secondary";
      }
      if (status == "Diterima") {
        return "success";
      }
      return "danger";
    },
  },
  methods: {
    checkAuthorizeRole,
    avatarText,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      });
    },
    getData() {
      this.isLoading = true;
      reqGetTrainingRequest(this.$route.params.trainingRequestId)
        .then((response) => {
          this.data = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Gagal",
            error.response.data.message || "User Gagal Ditambahkan"
          );
        });
    },
    submitForm() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          reqCreateTrainingRequest(this.form)
            .then((response) => {
              // stop loading
              this.isLoading = false;

              // show success toast
              this.showToast(
                "success",
                "Check",
                "Berhasil",
                response.data.message ||
                  "Request Pelatihan Berhasil Ditambahkan"
              );

              this.$router.push({
                name: "user-training-requests.show",
                params: {
                  trainingRequestId: response.data.data.id,
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              this.$refs.form.setErrors(
                this.mapErrorsField(error.response.data.errors)
              );

              // show error toast
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Gagal",
                error.response.data.message || "User Gagal Ditambahkan"
              );
            });
        }
      });
    },
    mapErrorsField(errors) {
      const mapErrors = [];
      const fields = {
        name: "Nama Pelatihan",
        purpose: "Tujuan Pelatihan",
        description: "Deskripsi Pelatihan",
      };
      for (let field in errors) {
        mapErrors[fields[field] ? fields[field] : field] = errors[field];
      }
      return mapErrors;
    },
  },
};
</script>
  